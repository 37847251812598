// --- Интеграция с amoCRM (PinscherCRM)

document.addEventListener("DOMContentLoaded", function() {

    console.log("PinscherCRM Integration");
    const utm_list = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_term', 'utm_content'];
    const secret_key = 'GiFYVQ8fggOHES0diGkqPIMYfjoxUFmH';

    // --- Функция для сохранения UTM параметров в localStorage
    utm_list.forEach(value => {
        const paramValue = getQueryParam(value);
        if (paramValue) {
            localStorage.setItem(value, paramValue);
        }
    });

    // --- Функция для очистки UTM параметров из localStorage
    const clearUtmFromLocalStorage = (utm) => {
        localStorage.removeItem(utm);
    };

    const forms = document.querySelectorAll('form');

    forms.forEach(function (form) {
        form.addEventListener('submit', function (e) {

            // console.log('Отправляем данные в amoCRM');
            var formData = new FormData(form);

            formData.append('secret_key', secret_key);
            formData.append('url', window.location.href);
            utm_list.forEach(utm => {
                formData.append(utm, localStorage.getItem(utm) || '');
            });

            fetch('https://demo2.pinschertest.ru/manager-integration/2025/amocrm/thnnru/modules/thnn.ru/index.php', {
                method: 'POST',
                body: formData
            }).then(function () {
                utm_list.forEach(clearUtmFromLocalStorage);
            });
            return true;
        });
    });

    // --- Получение GET параметров
    function getQueryParam(name) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name) || '';
    }
});
// --- / Интеграция с amoCRM (PinscherCRM)
